<template>
  <div>
    <!-- 选择任务按钮 -->
    <el-button
      type="primary"
      class="btn"
      @click="addService"
      icon="el-icon-plus"
      v-if="addServiceButton"
      >添加服务</el-button
      
    >

    <el-button type="success" class="btn" @click="addReason" icon="el-icon-plus"
    v-if="addReasonButton"
      >取消原因配置</el-button
    >

    <!-- 任务列表盒子 -->
    <div
      style="
        display: flex;
        flex-direction: column;
        height: calc(100vh - 280px);
        padding-top: 10px;
      "
    >
      <el-table
        border
        :data="dataList"
        default-expand-all
        style="width: 100%; text-align: center"
        height="100%"
        v-loading="tableLoading"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
      >
        <template #empty>
          <p>{{ tableLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>

        <el-table-column prop="mainUrl" label="主图" width="300" align="center">
          <template slot-scope="scope">
            <img
              :src=" scope.row.accessUrl"
              alt=""
              style="width: 50%"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="name" label="名称" width="180">
        </el-table-column>
        <el-table-column
          align="center"
          prop="price"
          label="收费标准"
          width="180"
        >
        </el-table-column>
        <el-table-column align="center" prop="type" label="类别" width="180">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1">修图服务</span>
            <span v-if="scope.row.type == 2">线下服务</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :formatter="columnDateFormat"
          prop="createTime"
          label="创建时间"
          width="240"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="status"
          label="套餐状态"
          width="220"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-color="#13ce66"
              :active-value="1"
              :inactive-value="0"
              @change="SwitchChange(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="address" label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="handleEdit(scope.row)"
              v-if="editButton"
              >编辑</el-button
            >
            <el-button
              size="small"
              type="danger"
              v-if="deletetButton"
              @click="handleDelete(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display: flex; justify-content: right; padding-top: 10px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 新增任务 -->
    <div>
      <el-dialog
        :title="dialogType === 1 ? '新增业务类型' : '修改业务类型'"
        :visible.sync="dialogVisible"
        width="630px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-form
          ref="serviceForm"
          :model="serviceForm"
          label-width="150px"
          :rules="serviceRules"
        >
          <el-row>
            <el-col :span="24">
              <el-form-item label="名称" prop="name">
                <el-input
                  style="width: 350px"
                  type="text"
                  v-model="serviceForm.name"
                  placeholder="请输入服务名称"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="收费标准" prop="price">
                <el-input
                  style="width: 350px"
                  type="text"
                  v-model="serviceForm.price"
                  placeholder="请输入服务价格"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="类型" prop="type">
                <el-select
                  v-model="serviceForm.type"
                  placeholder="请选择服务类型"
                  style="width: 350px"
                >
                  <el-option
                    v-for="item in serviceType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="主图" prop="mainUrl">
                <el-upload
                  action=""
                  class="avatar-uploader"
                  accept="image/jpeg,image/png"
                  list-type="picture"
                  :on-change="handleChange"
                  :auto-upload="false"
                >
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-upload2 avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="服务描述" prop="descs">
                <el-input
                  style="width: 350px"
                  type="textarea"
                  :rows="5"
                  placeholder="请输入服务描述"
                  v-model="serviceForm.descs"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button @click="handleColse" style="margin-right: 20px"
            >取 消
          </el-button>
          <el-button
            type="primary"
            @click="handleConfirm"
            :loading="confirmLoading"
            >保 存
          </el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 新增原因 -->
    <div>
      <el-dialog
        title="取消原因配置"
        :visible.sync="reasonVisible"
        width="650px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div v-for="(it, idx) in cancelForm" :key="idx">
          <el-form label-width="150px">
            <el-form-item :label="ReasonIndex" prop="descs">
              <el-input
                style="width: 240px"
                type="text"
                v-model="it.name"
                placeholder="请输入取消原因"
              >
              </el-input>
              <el-button
                style="color: red; margin-left: 20px"
                type="text"
                @click="delReason(it)"
                >删除</el-button
              >
              <el-button type="text" @click="handleReason(it)">
                保存
              </el-button>
              <el-button
                style="margin-left: 15px"
                type="text"
                @click="moreReason"
                v-if="idx == 0"
                >新增</el-button
              ></el-form-item
            >
          </el-form>
        </div>
      </el-dialog>
    </div>
    <div>
      <el-dialog
        append-to-body
        title="主图裁剪"
        ref="dialog"
        width="1200"
        class="img-cropper"
        :visible.sync="UPdialogVisible"
        :close-on-click-modal="false"
      >
        <div class="cropper-w" style="overflow: hidden">
          <div class="cropper" :style="{ width: '100%', height: '700px' }">
            <vueCropper
              ref="cropper"
              :img="option.img"
              :outputSize="option.size"
              :outputType="option.outputType"
              :info="option.info"
              :full="option.full"
              :canMove="option.canMove"
              :canMoveBox="option.canMoveBox"
              :original="option.original"
              :autoCrop="option.autoCrop"
              :autoCropWidth="option.autoCropWidth"
              :autoCropHeight="option.autoCropHeight"
              :fixedBox="option.fixedBox"
            ></vueCropper>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="UPdialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="finish" :loading="finishDialog"
            >确认</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<style scoped>
.avatar-uploader {
  width: 150px;
  height: 150px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  line-height: 150px;
  overflow: hidden;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  text-align: center;
}
.avatar {
  width: 75%;
  margin: 0 auto;
  display: block;
}
</style>

<script>
import {
  getqueryServices,
  uploadFile,
  addService,
  updateService,
  getIndex,
  deleteService,
  updateState,
  queryCancels,
  addCancel,
  deleteCancel,
} from "@/api/increment";
import { dateFormat } from "@/utils/date";
import axios from "axios";
import { getToken } from "@/utils/auth";
import { VueCropper } from "vue-cropper";
import { queryPageButton } from "@/api/permission";

export default {
  name: "incrementBusiness",

  components: {
    VueCropper,
  },
  data() {
    var res = /^\d+$|^\d+[.]?\d+$/;

    const validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("服务名称不能为空"));
      } else {
        callback();
      }
    };
    const validateType = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择服务类型"));
      } else {
        callback();
      }
    };
    const validatePrice = (rule, value, callback) => {
      if (value != "0" && !value) {
        callback(new Error("收费标准不能为空"));
      } else {
        if (res.test(value) == false) {
          callback(new Error("格式不正确，只能输入数字"));
        } else {
          callback();
        }
      }
    };

    return {
      // 列表
      dataList: [],
      tableLoading: false,
      currentPage: 1,
      pageSize: 20,
      total: 0,
      // 新增
      imageUrl: "",
      EditMainUrl: "",
      serviceForm: {},
      dialogType: 1,
      fileUrl: "",
      EditId: "",
      dialogVisible: false,
      confirmLoading: false,
      serviceType: [
        {
          label: "修图服务",
          value: 1,
        },
        {
          label: "线下服务",
          value: 2,
        },
      ],
      serviceRules: {
        name: [{ required: true, trigger: "blur", validator: validateName }],
        price: [{ required: true, trigger: "blur", validator: validatePrice }],
        type: [{ required: true, trigger: "blur", validator: validateType }],
        descs: [{ required: true, message: "请输入服务描述", trigger: "blur" }],
      },
      // 取消
      reasonVisible: false,
      ReasonIndex: "原因",
      cancelForm: [
        {
          name: "",
        },
      ],

      // 主图裁剪
      finishDialog: false,
      UPdialogVisible: false,
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 480, // 默认生成截图框宽度
        autoCropHeight: 640, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: true, // 上传图片按照原始比例渲染
      },
      //权限按钮
      addServiceButton:false,
      addReasonButton:false,
      editButton:false,
      deletetButton:false
      
    };
  },
  created() {
    this.query();
    this.queryButton();

  },
  methods: {
    queryButton() {
      const data = 126;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "addServiceButton") {
            this.addServiceButton = true;
          }   if (button.code === "addReasonButton") {
            this.addReasonButton = true;
          }  if (button.code === "editButton") {
            this.editButton = true;
          }  if (button.code === "deletetButton") {
            this.deletetButton = true;
          } 
        
          
        }
      });
    },
    query() {
      const data = {};
      this.tableLoading = true;
      getqueryServices(data, this.currentPage, this.pageSize).then((resp) => {
        this.dataList = resp.data.content;
        this.total = resp.data.totalElements;
        this.tableLoading = false;
      });
    },
    queryReason() {
      let data = {};
      queryCancels(data).then((resp) => {
        if (resp.code == 0) {
          this.cancelForm = resp.data;
        } else {
          this.$notify.error({
            title: "错误",
            message: resp.message,
          });
        }
      });
    },
    // 配置添加
    addReason() {
      this.queryReason();
      this.reasonVisible = true;
    },
    // 新增原因配置
    moreReason() {
      this.cancelForm.push({
        name: "",
      });
    },
    // 删除原因配置
    delReason(it) {
      let postData = it.id;
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteCancel(postData).then((resp) => {
            if (resp.code == 0) {
              this.$notify.success({
                title: "温馨提示",
                message: resp.message,
              });
            } else {
              this.$notify.error({
                title: "错误",
                message: resp.message,
              });
            }
            this.queryReason();
          });
        })
        .catch(() => {
          return false;
        });
    },
    handleReason(it) {
      let postData = {};
      postData.name = it.name;
      postData.id = it.id;
      const loading = this.$loading({
        lock: true,
        text: "正在保存...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      addCancel(postData).then((resp) => {
        if (resp.code == 0) {
          this.$notify.success({
            title: "温馨提示",
            message: resp.message,
          });
        } else {
          this.$notify.error({
            title: "错误",
            message: resp.message,
          });
        }
        loading.close();
        this.queryReason();
      });
    },
    // 新增服务类型
    addService() {
      this.dialogType = 1;
      this.serviceForm = {};
      this.imageUrl = "";
      this.dialogVisible = true;
    },
    // 上传照片
    handleChange(data) {
      let file = data.raw;
      this.filename = file.name;
      this.openCropper(file);
      return false;
    },
    openCropper(file) {
      var _this = this;
      console.log(_this);
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/PNG" ||
        file.type === "image/JPG";
      if (!isJPG) {
        this.$message.error("上传图片只能为jpg或png格式");
        return;
      }
      var reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }

        _this.option.img = data;
        _this.UPdialogVisible = true;
      };
      reader.readAsDataURL(file);
      // console.log(reader);
    },
    // 确认上传
    finish() {
      this.finishDialog = true;
      let _this = this;
      this.$refs.cropper.getCropBlob(async (data) => {
        let formData = new FormData();
        console.log(data);
        if (data) {
          var timestamp = new Date().getTime() + ".jpg";
          const newFile = new window.File([data], timestamp);
          formData.append("file", newFile);
          uploadFile(formData).then((resp) => {
            if (resp.code == 0) {
              this.$notify.success({
                title: "温馨提示",
                message: resp.message,
              });
              this.fileUrl = resp.data;
              if (this.fileUrl.length > 0) {
                this.getPhotoFile(this.fileUrl);
              }
              this.finishDialog = false;

              this.UPdialogVisible = false;
              this.serviceForm.file = file;
            }
          });
        } else {
          this.finishDialog = false;
          this.$message.warning("请重新上传图片");
        }
      });
      _this.$forceUpdate();
    },

    // 编辑服务
    handleEdit(data) {
      this.dialogType = 2;
      this.EditId = data.id;
      this.EditMainUrl = data.mainUrl;
      this.dialogVisible = true;
      if (this.$refs.serviceForm !== undefined) {
        //重置表单信息
        this.$refs.serviceForm.resetFields();
      }
      getIndex(this.EditId).then((resp) => {
        if (resp.code == 0) {
          this.serviceForm = resp.data;
          this.getPhotoFile(this.serviceForm.mainUrl);
        } else {
          this.$notify.error({
            title: "错误",
            message: resp.message,
          });
        }
      });
    },
    // 切换状态
    SwitchChange(data) {
      let postData = {};
      postData.status = data.status;
      postData.id = data.id;
      updateState(postData).then((resp) => {
        if (resp.code == 0) {
          this.$notify.success({
            title: "温馨提示",
            message: resp.message,
          });
        } else {
          this.$notify.error({
            title: "错误",
            message: resp.message,
          });
        }
        this.query();
      });
    },
    handleDelete(id) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteService(id).then((resp) => {
            if (resp.code == 0) {
              this.$notify.success({
                title: "温馨提示",
                message: resp.message,
              });
            } else {
              this.$notify.error({
                title: "错误",
                message: resp.message,
              });
            }
            this.query();
          });
        })
        .catch(() => {
          return false;
        });
    },
    // 关闭取消
    handleColse() {
      this.dialogVisible = false;
      this.confirmLoading = false;
    },
    // 新增保存
    handleConfirm() {
      // console.log(this.dialogType);
      this.$refs.serviceForm.validate((valid) => {
        if (valid) {
          const data = this.serviceForm;
          this.serviceForm.mainUrl = this.fileUrl;
          this.confirmLoading = true;
          if (this.dialogType == 1) {
            addService(data).then((resp) => {
              if (resp.code == 0) {
                this.$notify.success({
                  title: "温馨提示",
                  message: resp.message,
                });
              } else {
                this.$notify.error({
                  title: "错误",
                  message: resp.message,
                });
              }
              this.confirmLoading = false;
              this.dialogVisible = false;
              this.query();
            });
          } else if (this.dialogType == 2) {
            if (!this.fileUrl) {
              data.mainUrl = this.EditMainUrl;
            }
            updateService(data).then((resp) => {
              if (resp.code == 0) {
                this.dialogVisible = false;
                this.$notify.success({
                  title: "温馨提示",
                  message: resp.message,
                });
              } else {
                this.$notify.error({
                  title: "错误",
                  message: resp.message,
                });
                this.dialogVisible = false;
              }
              this.confirmLoading = false;
              this.query();
            });
          }
        }
      });
    },
    // 上传复核列表---查询图片
    getPhotoFile(fileid) {
      this.imageUrl = "";
      const url =
        "/api/csias/file/download/" +
        fileid +
        "?timestamp=" +
        new Date().getTime();
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        responseType: "blob",
        timeout: 180000,
      }).then(
        (response) => {
          // console.info(response);
          if (response.status === 200 && response.data) {
            const imgContent = response.data;
            const imgs = window.URL.createObjectURL(imgContent);
            this.imageUrl = imgs;
          } else {
            this.$notify.error({
              title: "错误",
              message: response.message,
            });
            this.imageUrl = "";
          }
        },
        (err) => {
          this.$notify.error({
            title: "错误",
            message: "照片加载失败，未查询到照片信息",
          });
          this.imageUrl = "";
        }
      );
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
    columnDateFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
  },
};
</script>

<
